<template>
    <div class="container">
        <div class="header">
            <el-select v-model="currentPlatform" @change="selectPlatform" placeholder="选择平台">
                <el-option
                v-for="item in platformOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value">
                </el-option>
            </el-select>
            <!-- <el-button @click="searchClick" icon="el-icon-search" circle></el-button> -->
            <el-input v-model="sortInput" placeholder="修改入口顺序(将入口顺序按ID排列,英文逗号隔开,例如:1,3,5,2,8)"></el-input>
            <el-button @click="sortClick" type="primary" round>修改排序</el-button>
        </div>
        <div class="table-wrap">
            <el-table
                :data="tableData"
                border
                stripe
                @row-dblclick="rowDbclick"
                style="width: 100%">
                <!-- <el-table-column type="selection" width="55"></el-table-column> -->
                <el-table-column type="index" width="50" align="center"></el-table-column>
                <el-table-column prop="id" label="id" width="70" align="center"></el-table-column>
                <el-table-column prop="logo" label="logo" width="100" align="center">
                    <!-- 图片的显示 -->
                    <template   slot-scope="scope">
                        <img :src="scope.row.logo"  min-width="50" height="50" />
                    </template>
                </el-table-column>
                <el-table-column prop="name" label="名称" width="170" align="center"></el-table-column>
                <el-table-column prop="bankName" label="类别" width="100" align="center"></el-table-column>
                <el-table-column prop="state" label="状态" width="120" align="center"></el-table-column>
                <el-table-column prop="version" label="开放条件" width="120" align="center"></el-table-column>
                <el-table-column prop="position" label="展示位置" width="100" align="center"></el-table-column>
                <el-table-column prop="showNew" label="展示new" width="100" align="center"></el-table-column>
                <el-table-column prop="warnText" label="提示文字" width="100" align="center"></el-table-column>
                <el-table-column prop="whiteList" label="白名单" width="180" align="center"></el-table-column>
                <el-table-column prop="gray" label="空间灰度区间" width="180" align="center"></el-table-column>
                <el-table-column prop="ext" label="Ext(额外参数，json格式)" width="200" align="center"></el-table-column>
                <el-table-column prop="delete" label="操作" width="90" align="center">
                    <template slot-scope="scope">
                        <el-button
                        size="mini"
                        @click="handleDelete(scope.$index, scope.row)">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <el-dialog title="功能入口配置" :visible.sync="showForm">
            <el-form class="form" ref="form" :model="entranceDetail" label-width="180px">
                <el-form-item label="选择图片上传：">
                    <input type="file"
                           accept="image/*"
                           @change="photoUpload">
                </el-form-item>
                <el-form-item label="入口id：">
                    <el-input v-model="entranceDetail.id" :disabled="!entranceDetail.isAdd" style="width: 200px"></el-input>
                </el-form-item>
                <el-form-item label="名称：">
                    <el-input v-model="entranceDetail.name" style="width: 200px"></el-input>
                </el-form-item>
                <el-form-item label="logo地址：">
                    <el-input v-model="entranceDetail.logo" style="width: 200px"></el-input>
                </el-form-item>
                <el-form-item label="链接（网页用）：">
                    <el-input v-model="entranceDetail.url" style="width: 200px"></el-input>
                </el-form-item>
                <el-form-item label="状态：">
                    <el-select v-model="entranceDetail.status" placeholder="">
                        <el-option
                        v-for="item in statusOptions"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="状态（无特殊情况不建议修改）：">
                    <el-select v-model="entranceDetail.type" placeholder="">
                        <el-option
                        v-for="item in typeOptions"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="展示位置：">
                    <el-select v-model="entranceDetail.pos" placeholder="">
                        <el-option
                        v-for="item in posOptions"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="是否展示”new“">
                    <el-select v-model="entranceDetail.isNew" placeholder="">
                        <el-option
                        v-for="item in newOptions"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="提示文字：">
                    <el-input v-model="entranceDetail.info" style="width: 200px"></el-input>
                </el-form-item>
                <el-form-item label="版本开放类别：">
                    <el-select v-model="entranceDetail.openType" placeholder="">
                        <el-option
                        v-for="item in verOptions"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="版本号（和上面条件联合使用）：">
                    <el-input v-model="entranceDetail.openVer" style="width: 200px"></el-input>
                </el-form-item>
                <el-form-item label="白名单：">
                    <el-input v-model="entranceDetail.whiteList" placeholder="空间ID以英文逗号隔开" style="width: 400px"></el-input>
                </el-form-item>
                <el-form-item label="空间灰度区间：">
                    <el-input v-model="entranceDetail.gray" placeholder="00-50" style="width: 400px"></el-input>
                </el-form-item>
                <el-form-item label="Ext（额外的参数，必须json格式）：">
                    <el-input type="textarea" v-model="entranceDetail.ext"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="onSubmit">{{entranceDetail.isAdd ? '创建':'立即修改'}}</el-button>
                    <el-button @click="cancelSubmit">取消</el-button>
                </el-form-item>
                </el-form>
        </el-dialog>
        <!-- <div v-if="showForm" class="form-shade">

        </div> -->
        <el-button
            class="add-btn"
            type="primary"
            icon="el-icon-plus"
            circle
            @click="addBtnClick">
        </el-button>
    </div>
</template>

<script>

import {getEntranceList, modifySort, getEntranceById, modifyEntrance, addEntrance, deleteEntrance} from '@/api/entranceApi.js';
import axios from 'axios';
import app from '@/config/app.js';

export default {
  data () {
    return {
      platformOptions: [{
        value: 1,
        label: 'iOS'
      }, {
        value: 0,
        label: '安卓'
      }],
      currentPlatform: 1,
      statusOptions: [{
        value: 0,
        label: '关闭'
      }, {
        value: 1,
        label: '开放'
      }, {
        value: 2,
        label: '高亮'
      }, {
        value: 3,
        label: '灰度'
      }],
      typeOptions: [{
        value: 0,
        label: '特殊类别'
      }, {
        value: 1,
        label: '打开网页'
      }],
      posOptions: [{
        value: 1,
        label: '小世界'
      }, {
        value: 2,
        label: '发现'
      }],
      newOptions: [{
        value: 0,
        label: '不展示'
      }, {
        value: 1,
        label: '展示'
      }],
      verOptions: [{
        value: -1,
        label: '无条件'
      }, {
        value: 0,
        label: '等于版本'
      }, {
        value: 1,
        label: '大于版本'
      }, {
        value: 2,
        label: '小于版本'
      }],
      sortInput: '',
      tableData: [],
      entranceDetail: {

      },
      showForm: false
    };
  },
  components: {

  },
  methods: {
    getData () {
      let that = this;
      getEntranceList({is_ios: that.currentPlatform})
        .then((res) => {
          console.log('get网络请求成功', res);
          let rows = res.rows;
          let tableData = [];
          rows.forEach((item) => {
            let data = {};
            data.id = item.id;
            data.name = item.name;
            data.logo = item.logo;
            data.bankName = item.type == 0 ? '特殊处理' : '打开网页';
            // status：状态；0不开放，1开放，2高亮，3灰度，否则无效状态
            switch (item.status) {
            case 0:
              data.state = '不开放';
              break;
            case 1:
              data.state = '开放';
              break;
            case 2:
              data.state = '高亮';
              break;
            case 3:
              data.state = '灰度';
              break;

            default:
              data.state = '无状态';
              break;
            }
            switch (item.openType) {
            case 0:
              data.version = `等于${item.openVer}`;
              break;
            case 1:
              data.version = `大于${item.openVer}`;
              break;
            case 2:
              data.version = `小于${item.openVer}`;
              break;

            default:
              data.version = '未限制';
              break;
            }
            data.position = item.pos == 1 ? '小世界' : '发现';
            data.showNew = item.isNew == 1 ? '展示' : '不展示';
            data.warnText = item.info;
            data.whiteList = item.whiteList;
            data.gray = item.gray;
            data.ext =  JSON.stringify(item.ext);
            if (item.pos == 1) {
              tableData.push(data);
            }
          });

          that.sortInput = res.module_list;

          let sortArray = res.module_list.split(',').map(Number);
          tableData.sort((prev, next) => {
            const p = sortArray.indexOf(prev.id);
            const n = sortArray.indexOf(next.id);
            return p - n;
          });
          that.tableData = tableData;

        })
        .catch((error) => {
          console.log('get网络请求失败', error);
        });
    },

    selectPlatform (e) {
      console.log('选择了平台', e);
      this.getData();
    },

    sortClick () {
      let that = this;
      modifySort({is_ios: that.currentPlatform, module_list: that.sortInput})
        .then((res) => {
          console.log('修改顺序成功：', res);
          that.getData();
        })
        .catch((error) => {
          console.log('修改顺序失败：', error);
        });
    },

    handleDelete (index, data) {
      console.log('删除了', index, data);
      let that = this;
      that.$confirm(`确认删除《${data.name}》的配置吗？`)
        .then((_) => {
          deleteEntrance({is_ios: that.currentPlatform, id: data.id})
            .then((res) => {
              console.log('删除配置成功：', res);
              that.getData();
            })
            .catch((error) => {
              console.log('删除配置失败', error);
            });
        })
        .catch((_) => {});
    },

    rowDbclick (row) {
      console.log('双击了某一行', row);
      let that = this;
      that.showForm = true;
      getEntranceById({is_ios: that.currentPlatform, id: row.id})
        .then((res) => {
          console.log('获取入口详情信息成功：', res);
          res.ext = JSON.stringify(res.ext);
          that.entranceDetail = res;
        })
        .catch((error) => {
          console.log('获取入口详情信息失败：', error);
        });
    },

    onSubmit () {
      this.showForm = false;
      let that = this;
      let param = {};
      param.is_ios = that.currentPlatform;
      param.module_id = that.entranceDetail.id;
      param.name = that.entranceDetail.name;
      param.logo = that.entranceDetail.logo;
      param.status = that.entranceDetail.status;
      param.type = that.entranceDetail.type;
      param.url = that.entranceDetail.url || '';
      param.ver_type = that.entranceDetail.openType;
      param.ver = that.entranceDetail.openVer || '';
      param.info = that.entranceDetail.info || '';
      param.pos = that.entranceDetail.pos;
      param.new = that.entranceDetail.isNew;
      param.white_list = that.entranceDetail.whiteList;
      param.gray = that.entranceDetail.gray;
      param.ext = that.entranceDetail.ext || '';

      if (that.entranceDetail.isAdd) {
        // 新创建
        addEntrance(param)
          .then((res) => {
            console.log('创建成功:', res);
            that.$message({
              message: '创建成功啦',
              type: 'success'
            });
            that.getData();
          })
          .catch((error) => {
            console.log('创建失败:', error);
          });
      } else {
        // 编辑配置
        modifyEntrance(param)
          .then((res) => {
            console.log('修改配置成功:', res);
            that.$message({
              message: '配置成功啦',
              type: 'success'
            });
            that.getData();
          })
          .catch((error) => {
            console.log('修改配置失败:', error);
          });
      }


    },

    cancelSubmit () {
      this.showForm = false;
    },

    addBtnClick () {
      this.entranceDetail = {
        isAdd: true,
        id: '',
        name: '',
        logo: '',
        url: '',
        status: 1,
        type: 0,
        pos: 1,
        isNew: 0,
        info: '',
        openType: -1,
        openVer: '',
        ext: ''
      };
      this.showForm = true;
    },

    photoUpload (e) {
      console.log('1111111', e);
      let that = this;
      let file = e.target.files[0];
      let param = new FormData();  // 创建form对象
      param.append('photo', file);  // 通过append向form对象添加数据
      // param.append('chunk', '0') // 添加form表单中其他数据
      console.log(param.get('file')); // FormData私有类对象，访问不到，可以通过get判断值是否传进去
      let config = {
        headers: {'Content-Type': 'multipart/form-data'}
      };
      // 添加请求头
      axios.post(app.apiUrl + '/manage/life/group/photo', param, config)
        .then((res) => {
          console.log('上传图片成功:', res.data);
          if (res.data.result == 1) {
            that.$message({
              message: '图片上传成功了',
              type: 'success'
            });
            that.entranceDetail.logo = res.data.original_url;
          }
        });
    }

  },
  created () {
    this.getData();
  }

};
</script>

<style lang="scss">
    .header {
        display: flex;
        padding: 10px;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid #c9c9c9;
        background: white;
    }

    .action-bar {
        padding: 10px;
        padding-bottom: 0;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        background: white;
    }

    .container{

    }

    .table-wrap{

    }

    .add-btn{
        position: fixed;
        width: 60px;
        height: 60px;
        right: 50px;
        bottom: 50px;
    }

</style>
import httpRequest from '@/utils/network/request';

// 获取入口配置列表
export const getEntranceList = function (params) {
  const url = '/entrance/list';
  return httpRequest.get(url, params);
};

// 修改列表排序
export const modifySort = function (params) {
  const url = '/entrance/modules/modify';
  return httpRequest.get(url, params);
};

// 获取入口配置详情
export const getEntranceById = function (params) {
  const url = '/entrance/getById';
  return httpRequest.get(url, params);
};

// 新增入口配置
export const addEntrance = function (params) {
  const url = '/entrance/new';
  return httpRequest.post(url, params);
};

// 修改入口配置
export const modifyEntrance = function (params) {
  const url = '/entrance/modify';
  return httpRequest.post(url, params);
};

// 删除入口配置
export const deleteEntrance = function (params) {
  const url = '/entrance/del';
  return httpRequest.post(url, params);
};

// 获取首页弹窗配置列表
export const getHomeAlertList = function (params) {
  const url = '/popupwindow/list';
  return httpRequest.get(url, params);
};

// 获取首页弹窗展示类型说明
export const getShowToTypes = function (params) {
  const url = '/popupwindow/getShowToTypes';
  return httpRequest.get(url, params);
};

// 编辑首页弹窗配置
export const editPopWindow = function (params) {
  const url = '/popupwindow/edit';
  return httpRequest.post(url, params);
};
